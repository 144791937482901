export const EXTERNAL_URLS = {
  signUp: '/sign_up',
  signIn: '/sign_in',
  settings: {
    _baseUrlMars: '/app/settings',
    _baseUrlStore: '/settings',
    get default() {return this._baseUrlMars;},
    get orders() {return `${this._baseUrlStore}/orders`;},
    get downloads() {return `${this._baseUrlStore}/downloads`;},
    get addressBook() {return `${this._baseUrlMars}/address-book`;},
    get ambassador() {return `${this._baseUrlStore}/my_ambassador`;},
    get club() {return `${this._baseUrlStore}/forever_club_membership`;}
  },
  boxCenter: '/digital-conversion/box-center',
  referrals: '/referrals',
  helpCenter: 'https://forever1.zendesk.com',
  helpCenterMetadataFaq: 'https://forever1.zendesk.com/hc/en-us/articles/360029672211-What-is-Metadata-',
  helpCenterVideoFaq: 'https://forever1.zendesk.com/hc/en-us/articles/360061141192-Optimizing-Video-for-Long-Term-Storage',
  admin: '/admin',
  p2p: '/pixels2pages',
  backOffice: '/back_office',
  becomeAmbassador: '/opportunity',
  storage: '/preserve-and-share',
  foreverClub: '/forever_club',
  forgotPassword: '/app/password-reset',
  video: '/video',
  facialRecognition: '/facial-recognition',
  facialRecognitionPlans: '/facial-recognition#plans',

  mobileAppIos: 'https://itunes.apple.com/us/app/forever/id808235823?ls=1&mt=8',

  addToCart: '/store/users/:userId/:category/:id/add_to_cart'
};
